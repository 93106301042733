$sidebar-bg-color: #fff;
$submenu-bg-color: #fff;
$submenu-bg-color-collapsed: #000;
$sidebar-color: #000000;
$highlight-color: #000000;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 25px;
$sidebar-width: 240px;
@import "~react-pro-sidebar/dist/scss/styles.scss";
body {
    margin: 0;
    height: 100vh;
    color: #000000;
}

#root {
    height: 100%;
}

body {
    height: auto;
}

.react-header {
    width: 100%;
    background: #006845;
    position: fixed;
    z-index: 1028;
}

.navbar-container {
    color: #fff;
}

.main-body {
    width: 100%;
    margin-left: 240px;
    margin-bottom: 30px;
    min-height: 100vh;
}

.pro-sidebar {
    height: 100%;
    position: fixed;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
    z-index: 998;
    .pro-menu {
        padding-top: 0;
        .pro-menu-item.pro-sub-menu {
            .pro-inner-list-item {
                .pro-inner-item:before {
                    box-shadow: none;
                    border-style: solid;
                    border-color: #000000;
                    border-width: 0 1px 1px 0;
                    padding: 2.5px;
                    vertical-align: middle;
                    transition: transform 0.3s;
                    transform: rotate(-45deg);
                    border-radius: 0;
                }
            }
            .pro-inner-item {
                .pro-arrow-wrapper {
                    .pro-arrow {
                        border-width: 0 1px 1px 0;
                    }
                }
            }
            .pro-inner-list-item {
                padding-left: 0;
                .pro-inner-item {
                    padding-left: 40px;
                }
            }
        }
        ul {
            .pro-sub-menu {
                .pro-inner-list-item {
                    div {
                        ul {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
        .pro-menu-item>.pro-inner-item {
            background-color: #fff;
            border-left: 3px solid #fff;
        }
        .pro-menu-item.active>.pro-inner-item,
        .pro-menu-item>.pro-inner-item:hover {
            background-color: #eeeeee;
            border-color: var(--primary-hover);
        }
        .pro-menu-item.pro-sub-menu.open .pro-inner-item {
            border-color: var(--primary-hover);
        }
    }
}

.app {
    height: 100%;
    display: flex;
    position: relative;
    .btn-toggle {
        cursor: pointer;
        width: 35px;
        height: 35px;
        background: #353535;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        display: none;
    }
    .sidebar-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .sidebar-btn {
            padding: 1px 15px;
            border-radius: 40px;
            background: rgba(255, 255, 255, 0.05);
            color: #adadad;
            text-decoration: none;
            margin: 0 auto;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-overflow: ellipsis;
            overflow: hidden;
            span {
                margin-left: 5px;
                font-size: 13px;
            }
            &:hover {
                color: $highlight-color;
            }
        }
    }
    .collapsed {
        .sidebar-btn {
            display: inline-block;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            line-height: 40px;
            padding: 0;
        }
    }
    main {
        header {
            margin-bottom: 40px;
            border-bottom: 1px solid #efefef;
            h1 {
                display: flex;
                align-items: center;
                transform: translateX(-20px);
            }
        }
        footer {
            margin-top: auto;
            color: #888;
            text-align: center;
            a {
                text-decoration: none;
                color: #888;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .social-bagdes {
        margin-top: 10px;
        img {
            margin: 5px;
        }
    }
    .block {
        display: flex;
        margin-bottom: 24px;
        font-size: 14px;
        color: #545454;
        >span {
            margin-left: 10px;
        }
    }
    &.rtl {
        direction: rtl;
        text-align: right;
        header {
            h1 {
                transform: translateX(20px);
            }
        }
        .block {
            direction: rtl;
            >span {
                margin-left: 0;
                margin-right: 10px;
            }
        }
        .sidebar-btn-wrapper {
            .sidebar-btn {
                span {
                    margin-left: 0;
                    margin-right: 5px;
                }
            }
        }
    }
    @media (max-width: 767.98px) {
        overflow-x: hidden;
        header {
            h1 {
                font-size: 24px;
            }
        }
    }
    @media (max-width: $breakpoint-md) {
        .btn-toggle {
            display: flex;
        }
        &.rtl {
            .btn-toggle {
                margin-left: auto;
            }
        }
    }
}

.badge {
    padding: 3px 6px;
    font-size: 9px;
    letter-spacing: 1px;
    border-radius: 15px;
    &.red {
        color: #ffffff;
        background: #d63030;
    }
    &.gray {
        color: #ffffff;
        background: #777777;
    }
    &.yellow {
        color: #000000;
        background: #ffd331;
    }
}